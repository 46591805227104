import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import { patientShape } from '../appointments/propShapes'
import InputToggleText from '../forms/fields/InputToggleText'
import CircleItem from '../CircleItem'
import CopyToClipboard from '../CopyToClipboard'
import ClinicPatientVaccineCard from './ClinicPatientVaccineCard'
import { s_phone } from '../../lib/sanitizers'
import { messageError, isValid } from './errors'
import { v_nationalId, v_phone, v_name } from '../../lib/validations'
import { f_nationalId, f_phone } from '../../lib/filters'
import ClinicContext from './ClinicContext'
import moment from 'moment-timezone'

const ClinicPatientListItem = ({
  patient,
  onSave,
  onDelete,
  isLastItem,
  editable,
  appointment,
  onCirclesClick,
}) => {
  const { assetRootUrl, allTrue } = useContext(ClinicContext)
  const isPersisted = patient.id > -1

  const [state, setState] = useState({
    patient: { ...patient },
    editing: !isPersisted,
    validations: {
      name: v_name(patient.name),
      phoneNumber: v_phone(patient.phoneNumber),
      nationalId: v_nationalId(patient.nationalId),
    },
    openModal: false,
    error: null,
  })

  const canSave = useRef(false)

  const edit = () => {
    setState({ ...state, editing: true })
  }

  const savePatient = () => {
    if (isValid(state.validations)) {
      setState({ ...state, editing: false, error: null })
      if (onSave) onSave(state.patient, false)
    } else {
      setState({ ...state, error: messageError(state.validations) })
    }
  }

  const deletePatient = () => {
    if (window.confirm('Do you really want to delete this patient')) {
      setState({ ...state, editing: false })
      if (onDelete) onDelete(state.patient)
    }
  }

  const cancel = () => {
    setState({ patient: patient, editing: false, error: null })
    if (onSave) onSave(state.patient, true)
  }

  useEffect(() => {
    if (!canSave.current) return
    canSave.current = false
    if (onSave) onSave(state.patient, false)
  }, [state.patient])

  const changeAttribute = (value, valid, name) => {
    setState({
      ...state,
      patient: { ...state.patient, [`${name}`]: value },
      validations: { ...state.validations, [`${name}`]: valid },
    })
    if (name == 'appearedAt') canSave.current = true
  }

  const triggerModal = () => {
    setState({ ...state, openModal: !state.openModal })
  }

  const hasOptions = () => {
    const res =
      (!!state.patient.options &&
        Object.entries(state.patient.options)?.length > 0) ||
      appointment.appointment.products.some(
        (p) => p.consentFields.length > 0,
      )
    return res
  }

  const hasConsentForm = () => {
    if (appointment.order && appointment.order.id === 'new')
      return false
    return (
      (appointment &&
        appointment.appointment &&
        appointment.appointment.products &&
        appointment.appointment.products.some(
          (p) => p.consentFields.length > 0,
        )) ||
      (appointment &&
        appointment.order &&
        appointment.order.products.some(
          (p) => p.consentFields.length > 0,
        ))
    )
  }

  const needsConsent = () => {
    const res =
      appointment?.products?.some(
        (p) => p.consentFields.length !== 0,
      ) ||
      (appointment.appointment &&
        appointment.appointment.products.some(
          (p) => p.consentFields.length !== 0,
        ))
    return res
  }

  const isANewAppointment = () => {
    return appointment.order && appointment.order.id === 'new'
  }

  useEffect(() => {
    if (needsConsent() && !hasOptions()) {
      const productWithOptions = appointment.products.find(
        (p) => p.consentFields.length !== 0,
      )
      const options = {}
      productWithOptions.consentFields.forEach((field) => {
        return (options[`${field.slug}`] = false)
      })
      setState((state) => {
        return { ...state, patient: { ...patient, options } }
      })
    } else {
      setState({ ...state, patient: { ...patient } })
    }
  }, [patient])

  const clickOnCircle = (e, value) => {
    if (!editable || state.editing) return
    if (value == 'appearedAt' && !!state.patient.appearedAt) return
    if (value == 'readyAt' && statusReadyAt() == 'invalid') return
    if (
      value == 'readyAt' &&
      hasOptions() &&
      allTrue(state.patient.options)
    )
      return

    onCirclesClick(e, state.patient, value)
  }

  const statusReadyAt = () => {
    switch (true) {
      case hasConsentForm() &&
        hasOptions() &&
        !allTrue(state.patient.options):
        return 'invalid'
      case hasConsentForm() &&
        hasOptions() &&
        allTrue(state.patient.options):
        return 'done'
      case !!state.patient.readyAt:
        return 'done'
      case hasConsentForm() && !hasOptions():
        return 'invalid'
      default:
        return 'notDone'
    }
  }

  return (
    <>
      {state.openModal && state.patient && hasConsentForm() ? (
        <ClinicPatientVaccineCard
          item={state.patient}
          onClose={triggerModal}
          appointment={appointment.appointment}
          editable={editable}
          onCirclesClick={onCirclesClick}
        />
      ) : null}
      <div
        className="clinic-list-item row"
        key={state.patient.id}
        style={{
          backgroundColor: state.editing ? 'transparent' : '#fff',
        }}
      >
        <input type="hidden" value={state.patient.id} />
        <div
          className={`col-1 ${state.patient && 'pt-2'}`}
          style={{ cursor: 'pointer' }}
          onClick={(e) => clickOnCircle(e, 'appearedAt')}
        >
          {!state.editing && !state.patient.appearedAt && (
            <CircleItem
              id={`appointment_item_appeared_at`}
              className="circle-item applied_at"
              status={'notDone'}
            />
          )}
          {!state.editing && state.patient.appearedAt && (
            <span className="clock-arrived-at">
              {moment(state.patient.appearedAt).format('HH:mm')}
            </span>
          )}
        </div>
        <div className="col-2">
          <InputToggleText
            label="Navn"
            name="name"
            value={state.patient.name}
            className="text-wrap"
            editable={state.editing}
            onChange={changeAttribute}
            validate={v_name}
            title={state.patient.name}
          />
        </div>
        <div className="col-2">
          <InputToggleText
            name="phoneNumber"
            label="Tlf. nummer"
            value={state.patient.phoneNumber}
            sanitize={s_phone}
            editable={state.editing}
            onChange={changeAttribute}
            validate={v_phone}
            filter={f_phone}
          />
          {!state.editing && (
            <CopyToClipboard
              clipboardIconPath={assetRootUrl.clipboardIcon}
              value={state.patient.phoneNumber}
              stopPropagation={true}
              showValue={false}
            />
          )}
        </div>
        <div className="col-2">
          <InputToggleText
            name="nationalId"
            label="P. nummer"
            value={state.patient.nationalId}
            editable={state.editing}
            onChange={changeAttribute}
            validate={v_nationalId}
            filter={f_nationalId}
          />
          {!state.editing && (
            <CopyToClipboard
              clipboardIconPath={assetRootUrl.clipboardIcon}
              value={state.patient.nationalId}
              stopPropagation={true}
              showValue={false}
            />
          )}
        </div>
        {!state.editing && (
          <>
            <div className="col-2">
              {hasOptions() &&
              hasConsentForm() &&
              !isANewAppointment() ? (
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    gap: '1em',
                  }}
                  onClick={(e) => clickOnCircle(e, 'readyAt')}
                >
                  <CircleItem
                    title="Klar"
                    id={`appointment_item_ready_at`}
                    className="circle-item applied_at mr-2"
                    status={statusReadyAt()}
                  />
                  <Button
                    className="btn-link"
                    style={{ marginTop: '0.5rem' }}
                    onClick={triggerModal}
                    label="Vis..."
                    dataType="clinic-patient"
                  />
                </div>
              ) : null}
            </div>
            <div
              className="col-1"
              style={{ cursor: 'pointer' }}
              onClick={(e) =>
                clickOnCircle(e, 'productAdministeredAt')
              }
            >
              <CircleItem
                title="Utfort"
                id={`appointment_item_adimistered_at`}
                className="circle-item applied_at"
                status={
                  state.patient.productAdministeredAt
                    ? 'done'
                    : 'notDone'
                }
              />
            </div>
          </>
        )}

        {!state.editing && (
          <div
            className="col-1"
            style={{ cursor: 'pointer' }}
            onClick={(e) => clickOnCircle(e, 'sysvacRegisteredAt')}
          >
            <CircleItem
              title="Journalført"
              id={`appointment_item_documented_at`}
              className="circle-item applied_at"
              status={
                state.patient.sysvacRegisteredAt ? 'done' : 'notDone'
              }
            />
          </div>
        )}
        {state.editing && (
          <div className="col-5 action-bar">
            {isPersisted && !isLastItem && (
              <Button
                onClick={deletePatient}
                className="btn-link mx-1"
                style={{
                  textDecoration: 'underline',
                }}
              >
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#d72520',
                  }}
                >
                  Slett
                </span>
              </Button>
            )}
            <Button
              className="btn-link mx-1"
              style={{
                textDecoration: 'underline',
              }}
              onClick={cancel}
              label="Avbryt"
              dataType="clinic-patient"
            />
            <Button className="btn-link mx-1" onClick={savePatient}>
              <span
                style={{
                  color: '#281837',
                  textDecoration: 'underline',
                }}
              >
                Bekreft
              </span>
            </Button>
          </div>
        )}
        {!state.editing && editable && (
          <div className="col-1 text-right">
            <Button className="btn-link mx-1" onClick={edit}>
              <img src={assetRootUrl.pen} />
            </Button>
          </div>
        )}
        {state.error && (
          <p className="small error-message center mb-0">
            {state.error}
          </p>
        )}
      </div>
    </>
  )
}

ClinicPatientListItem.propTypes = {
  patient: patientShape,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  isLastItem: PropTypes.bool,
  editable: PropTypes.bool,
  appointment: PropTypes.object,
  onCirclesClick: PropTypes.func,
}

export default ClinicPatientListItem
