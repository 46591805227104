import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const defineCircleClass = (status) => {
  switch (status) {
    case 'loading':
      return 'loading'
    case 'done':
      return 'done'
    case 'notDone':
      return 'not-done'
    case 'invalid':
      return 'invalid'
  }
}

const CircleItem = (props) => {
  const [loading, setLoading] = useState(false)
  const circleClass = useMemo(() => {
    return defineCircleClass(loading ? 'loading' : props.status)
  }, [props.status, loading])

  const inputProps = _.pick(props, [
    'name',
    'autoComplete',
    'maxLength',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'inputMode',
    'readOnly',
    'id',
    'title',
  ])

  useEffect(() => {
    setLoading(false)
  }, [props.status])

  return (
    <div
      className="col-sm-1"
      style={{ paddingLeft: '10px' }}
      onClick={(e) => {
        if (loading) return
        setLoading(true)
        props.onClick(e, props.item, props.itemProperty)
      }}
      title={props.title}
    >
      <div className={props.className} id={props.id} {...inputProps}>
        <div className={circleClass}>{loading && '⏳'}</div>
      </div>
    </div>
  )
}

CircleItem.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default CircleItem
